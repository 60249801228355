import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import StandardFormat from './Components/StandardFormat';
import Homepage from './Components/Homepage';
import Games from './Components/Games';
import About from './Components/About';
import Press from './Components/Press';

function App() {return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					{/* <Route path="/" element={<StandardFormat/>}> */}
						<Route index element={<Homepage />} />
						<Route path="games" element={<Games />} />
						<Route path="about" element={<About />} />
						<Route path="press" element={<Press />} />
						<Route path="*" element={<p>404</p>} />
					{/* </Route> */}
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
