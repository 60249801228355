import React, { FC, useState, ReactNode } from 'react';
import ParallaxSlice from './ParallaxSlice';
import StandardFormat from './StandardFormat';
import { NAVBAR_HEIGHT } from './Navbar';



interface AboutProps {

}



const About:FC<AboutProps> = ({  }) => {
	return (
		<StandardFormat>
			<ParallaxSlice
				mediaRatio={0.5625}
				background={
					<img
						className='h-full w-full opacity-20 blur-sm'
						src='/PressBg.png'
					/>
					// <video className='opacity-20 h-full w-full' src='/HomepageVideo2_v2.mp4' autoPlay muted loop/>
				}
				children={
					<div
						className='xl:w-[1000px] m-auto'
						style={{ paddingTop: NAVBAR_HEIGHT }}
					>
						<p className='text-white text-8xl font-slapstag_solid mb-10'>
							Please bare with us as we build this website!
						</p>
					</div>
				}
			/>
		</StandardFormat>
	);
};
export default About;
