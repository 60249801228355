import React, { FC, useState, useEffect, useRef, ReactNode } from 'react';



interface DividerProps {
	message: string;
	repeatOverride?: number;
}



const Divider:FC<DividerProps> = ({ message, repeatOverride }) => {
	repeatOverride ??= 25;

	return (
		<>
			<div
				className='flex justify-center py-4 bg-[#F9A21D] text-[#230C0F] overflow-clip whitespace-nowrap text-1xl sm:text-3xl lg:text-5xl font-slapstag_solid'
			>
				{Array(repeatOverride).fill(1).map((e, i) => <React.Fragment key={i}>&nbsp;&nbsp;{message}&nbsp;&nbsp;</React.Fragment>)}
			</div>
		</>
	);
};
export default Divider;
