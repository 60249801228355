import React, { FC, useState, ReactNode } from 'react';



interface FooterProps {

}



const Footer:FC<FooterProps> = ({  }) => {
	return (
		<div className='flex w-full bg-[#F9A21D] text-[#230C0F] text-wh text-xl font-bold'>
			<p className='mx-auto py-8'>
				SLAP STAG LLC © 2022-2024
			</p>
		</div>
	);
};
export default Footer;
