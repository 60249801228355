import React, { FC, useState, ReactNode } from 'react';
import ParallaxSlice from './ParallaxSlice';
import StandardFormat from './StandardFormat';
import { NAVBAR_HEIGHT } from './Navbar';
import { Link } from 'react-router-dom';
import Divider from './Divider';
import { PrimaryExternalLinks } from '../Common';



interface GamesProps {

}



const Games:FC<GamesProps> = ({  }) => {
	return (
		<StandardFormat>
			{/* TITLE PARALLAX SLICE */}
			<ParallaxSlice
				debug
				mediaRatio={0.5625}
				background={
					// <img
					// 	className='h-full w-full'
					// 	src='https://wallpapers.com/images/hd/blue-aesthetic-moon-df8850p673zj275y.jpg'
					// />
					<video className='opacity-20 h-full w-full' src='/Video2_v2.mp4' autoPlay muted loop/>
				}
				children={
					<div
						className='w-[340px] sm:w-[600px] md:w-[700px] xl:w-[1000px] mx-auto my-16 flex flex-col justify-center text-white'
						style={{ paddingTop: NAVBAR_HEIGHT }}
					>
						<img
							src='CBT_DD_Logo.png'
							alt='Cardboard Battle Tanks:Desk Division'
							className='max-w-[80%] mx-auto'
						/>
						<div className='text-lg sm:text-xl md:lg:text-2xl lg:text-3xl text-justify'>
							<p className='mb-10'>
								The top-down, arcade-like cardboard tank shooter you've been looking for!
							</p>
							<p className='mb-10'>
								Play alongside - or against - your friends, in chaos-fueled game modes such as the classic crowd pleasers: Team Deathmatch, King of the Hill, Capture the Flag, and more! Up to ten friends can play together through splitscreen, network, or both simultaneously.
							</p>
							<p className='mb-10'>
								• Lots of game modes!<br/>
								• Point-based loadout system!<br/>
								• Unlockable tank parts!<br/>
								• Map editor & Steam Workshop support!<br/>
							</p>
							<p className=''>
								Scroll down to find out how to play!
							</p>
						</div>
					</div>
				}
			/>
			{/* BREAK */}
			<Divider message='Cardboard Battle Tanks'/>
			{/* 4 IMAGE */}
			<ParallaxSlice
				mediaRatio={0.5625}
				background={

					<div
						className='h-full w-full opacity-80 grid grid-cols-2'
					>
						<img src='CBT_Still_0.png'/>
						<img src='CBT_Still_1.png'/>
						<img src='CBT_Still_2.png'/>
						<img src='CBT_Still_3.png'/>
					</div>
					// <video className='opacity-20 h-full w-full' src='/HomepageVideo2_v2.mp4' autoPlay muted loop/>
				}
				children={
					<div className='w-[340px] sm:w-[600px] md:w-[700px] xl:w-[1000px] mx-auto my-16 flex flex-col justify-center text-white'>
						
					</div>
				}
			/>
			{/* BREAK */}
			<Divider message='Cardboard Battle Tanks'/>
			{/* PLAY PARALLAX SLICE */}
			<ParallaxSlice
				mediaRatio={0.5625}
				background={
					// <img
					// 	className='h-full w-full'
					// 	src='https://wallpapers.com/images/hd/blue-aesthetic-moon-df8850p673zj275y.jpg'
					// />
					<video className='opacity-20 h-full w-full' src='/Video1_v2.mp4' autoPlay muted loop/>
				}
				children={
					<div className='w-[340px] sm:w-[600px] md:w-[700px] xl:w-[1000px] mx-auto my-16 flex flex-col justify-center text-white'>
						<p className='text-xl sm:text-3xl md:text-4xl lg:text-5xl font-slapstag_solid mb-10'>
							Ready to enter the battlefield?
						</p>
						<div className='text-lg sm:text-xl md:lg:text-2xl lg:text-3xl text-justify'>
							<p className='mb-10'>
								Join our Discord <Link to='https://discord.gg/R6zmACcw6r' target='_blank' rel='noopener noreferrer' className='underline'>HERE</Link>, or find us on Steam:
							</p>
						</div>
						<iframe
							src='https://store.steampowered.com/widget/2691960/'
							width='646'
							height='190'
						/>
					</div>
				}
			/>
		</StandardFormat>
	);
};
export default Games;
