import React, { FC, useState, ReactNode } from 'react';
import ParallaxSlice from './ParallaxSlice';
import StandardFormat from './StandardFormat';
import { NAVBAR_HEIGHT } from './Navbar';



interface PressProps {

}



const Press:FC<PressProps> = ({  }) => {
	return (
		<StandardFormat>
			<ParallaxSlice
				mediaRatio={0.5625}
				background={
					<img
						className='h-full w-full opacity-20 blur-[1.5px]'
						src='/CBT_Booth.jpg'
					/>
					// <video className='opacity-20 h-full w-full' src='/HomepageVideo2_v2.mp4' autoPlay muted loop/>
				}
				children={
					<div
						className='xl:w-[1000px] m-auto'
						style={{ paddingTop: NAVBAR_HEIGHT }}
					>
						<div className='w-[340px] sm:w-[600px] md:w-[700px] xl:w-[1000px] m-auto text-white'>
							<p className='text-xl sm:text-3xl md:text-4xl lg:text-5xl font-slapstag_solid'>
								Welcome, paparazzi
							</p>
							<p className='text-md sm:text-xl md:text-3xl lg:text-4xl font-slapstag_solid mb-10'>
								(and anyone else!)
							</p>
							<div className='text-lg sm:text-xl md:lg:text-2xl lg:text-3xl text-justify'>
								<p className='mb-10'>
									We are a small studio! Please bare with us as we flush out this page for content in the near-future!
								</p>
								<p className='mb-10'>
									For now, please direct any requests for press media and such to <span className='underline'>inquiry@slapstag.com</span>, or message us on Discord!
								</p>
								<p className=''>
									Thank you! :)
								</p>
							</div>
						</div>
					</div>
				}
			/>
		</StandardFormat>
	);
};
export default Press;
