import React, { FC, useState, useEffect, useRef, ReactNode } from 'react';
import { NAVBAR_HEIGHT } from './Navbar';
import { Parallax, Background } from 'react-parallax';



interface ParallaxSliceProps {
	mediaRatio: number;
	strength?: number;
	background: ReactNode;
	children?: ReactNode;
	debug?: boolean;
}



const ParallaxSlice:FC<ParallaxSliceProps> = ({ mediaRatio, strength, background, children, debug }) => {
	strength ??= 500;

	const divRef = useRef<HTMLDivElement>(null);
	const [size, setSize] = useState({x: 0, y: 0});

	useEffect(() => {
		if (!divRef.current)
			return;
		const resizeObserver = new ResizeObserver(() => {
			if (debug)
			console.log({x: (divRef.current?.clientWidth ?? 0), y: (divRef.current?.clientHeight ?? 0)});
			setSize({x: (divRef.current?.clientWidth ?? 0), y: (divRef.current?.clientHeight ?? 0)});
		});
		resizeObserver.observe(divRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	const getBackgroundSize = () => {
		let output = {x: size.x, y: size.x * mediaRatio};
		if (output.x < size.x || output.y < size.y)
		output = {x: size.y * (1 / mediaRatio), y: size.y};
		return output;
	};

	// useEffect(() => {console.log(getBackgroundSize())}, [size]);

	return (
		<>
			<Parallax strength={strength}>
				<Background className="custom-bg">
					<div
						className='bg-[#230C0F]'
						style={{width: getBackgroundSize().x, height: getBackgroundSize().y}}
					>
						{background}
					</div>
				</Background>
				<div
					ref={divRef}
					className='min-h-screen w-screen min-w-[360px] flex'
				>
					{children}
				</div>
			</Parallax>
		</>
	);
};
export default ParallaxSlice;
