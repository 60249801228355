import React, { FC, useState, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { PrimaryExternalLinks } from '../Common';

export const NAVBAR_HEIGHT = '6rem';
const INNER_HEIGHT = '3.5rem';

const NavbarIntenralLinks = [
	{label: 'home', link: ''},
	{label: 'games'},
	{label: 'about'},
	{label: 'press'},
];



interface NavbarProps {
	width: number
}



const Navbar:FC<NavbarProps> = ({ width }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const generateContent = () => (
		<>
			<div className='flex flex-col md:flex-row text-3xl whitespace-nowrap items-center'>
				{NavbarIntenralLinks.map(e => (
					<Link key={e.label} to={`/${e.link ?? e.label}`} className='flex flex-col justify-center w-min first:ml-0 md:ml-4 lg:ml-8 my-2 md:my-0'>
						<div className='h-0 w-0'>
							<p className='relative -top-[.1rem] -left-[.1rem] text-[#F9A21D] font-slapstag_solid'>{e.label}</p>
						</div>
						<p className='text-[#EC2127] font-slapstag_hollow'>{e.label}</p>
					</Link>
				))}
			</div>
			<div className='grow'/>
			<div className='flex text-3xl whitespace-nowrap items-center justify-center'>
				{PrimaryExternalLinks.map(e => (
					<Link
						key={e.link}
						to={e.link}
						target='_blank'
						rel='noopener noreferrer'
						className='h-full flex flex-col justify-center text-[#F9A21D] ml-4 lg:ml-8 last:mr-4'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='currentColor'
							viewBox='0 0 50 50'
							strokeWidth={.1}
							stroke='currentColor'
							style={{ width: e.navbarSize, height: e.navbarSize}}
						>
							<path d={e.path}/>
						</svg>
					</Link>
				))}
			</div>
		</>
	);

	return (
		<div
			className='z-20 fixed h-0 flex flex-col'
			style={{ width }}
		>
			<div
				className='fixed w-full flex flex-col'
				style={{ width, height: NAVBAR_HEIGHT }}
			>
				<div
					style={{height: `calc(${NAVBAR_HEIGHT} - ${INNER_HEIGHT})`}}
				/>
				<div
					className='w-full bg-[#230C0F] border-[#F9A21D] border-y-2 shadow-lg'
					style={{height: INNER_HEIGHT}}
				>
					<img
						src='/StagHead.png'
						className='w-[5.4rem] ml-4 b-0 absolute bottom-[2px]'
					/>
					{/* Optionally render the desktop content */}
					<div className='h-full w-full hidden md:flex pl-28 pr-4 text-3xl whitespace-nowrap'>
						{generateContent()}
					</div>
					{/* Optionally render the mobile content */}
					<div className='h-full w-full flex md:hidden pl-24 pr-4 text-3xl whitespace-nowrap'>
						<div className='h-full flex flex-col justify-center'>
							<div className='h-0 w-0'>
								<p className='relative -top-[.1rem] -left-[.1rem] text-[#F9A21D] font-slapstag_solid'>SLAP STAG</p>
							</div>
							<p className='text-[#EC2127] font-slapstag_hollow'>SLAP STAG</p>
						</div>
						<div className='grow'/>
						<svg
							className='text-[#F9A21D] cursor-pointer'
							onClick={e => { setIsMenuOpen(!isMenuOpen); e.stopPropagation(); }}
							xmlns='http://www.w3.org/2000/svg'
							fill='currentColor'
							viewBox='0 0 50 50'
							strokeWidth={.1}
							stroke='currentColor'
							style={{ width: '3rem', height: '3rem'}}
						>
							<path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
						</svg>
					</div>
					{isMenuOpen &&
						<div
							className='bg-[#230C0F99] w-full'
							style={{ height: `calc(100vh - ${NAVBAR_HEIGHT})`}}
							onClick={e => { setIsMenuOpen(!isMenuOpen); e.stopPropagation(); }}
						>
							<div
								className='w-full max-h-full bg-[#230C0F] border-[#F9A21D] border-y-2 overflow-auto flex flex-col py-2'
								onClick={e => e.stopPropagation()}
							>
								{generateContent()}
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
};
export default Navbar;
