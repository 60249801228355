import React, { FC, useState, useEffect, useRef, ReactNode } from 'react';
import { NAVBAR_HEIGHT } from './Navbar';
import ParallaxSlice from './ParallaxSlice';
import StandardFormat from './StandardFormat';
import { Link } from 'react-router-dom';
import Divider from './Divider';
import { PrimaryExternalLinks, SecondaryExternalLinks } from '../Common';



interface HomepageProps {

}



const Homepage:FC<HomepageProps> = ({  }) => {
	const divRef = useRef<HTMLDivElement>(null);
	const [size, setSize] = useState({x: 0, y: 0});

	useEffect(() => {
		if (!divRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setSize({x: (divRef.current?.clientWidth ?? 0), y: (divRef.current?.clientHeight ?? 0)})
		});
		resizeObserver.observe(divRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<StandardFormat>
			{/* TITLE PARALLAX SLICE */}
			<ParallaxSlice
				mediaRatio={0.5625}//1080/1920 (the resolution of the video file)
				background={<video className='opacity-20 h-full w-full' src='/Video1_v2.mp4' autoPlay muted loop/>}
				children={
					<div className='m-auto h-[60vh] flex' style={{ paddingTop: NAVBAR_HEIGHT }}>
						<div
							ref={divRef}
							className='aspect-[1.454545] max-h-[90%] max-w-[90%] min-w-[90%] m-auto flex flex-row bg-[#230C0F]'
							style={{ borderRadius: size.x / 20, padding: size.x / 10 }}
						>
							<img src='/MainTile.png' className='h-full w-auto align-text-bottom'/>
							<span 
								className='text-[#F9A21D] font-slapstag_solid mt-auto'
								style={{fontSize: size.x / 38}}
							>
								LLC
							</span>
						</div>
					</div>
				}
			/>
			{/* BREAK */}
			<Divider message='Slap Stag'/>
			{/* CARDBOARD BATTLE TANKS */}
			<ParallaxSlice
				mediaRatio={0.5625}
				background={
					// <img
					// 	className='h-full w-full'
					// 	src='https://wallpapers.com/images/hd/blue-aesthetic-moon-df8850p673zj275y.jpg'
					// />
					<img
						className='h-full w-full opacity-20 blur-[1.5px]'
						src='/CBT_Still_3.png'
					/>
				}
				children={
					<div className='w-[340px] sm:w-[600px] md:w-[700px] xl:w-[1000px] mx-auto my-16 flex flex-col justify-center text-white'>
						<img
							src='CBT_DD_Logo.png'
							alt='Cardboard Battle Tanks:Desk Division'
							className='max-w-[80%] mx-auto'
						/>
						<div className='text-lg sm:text-xl md:lg:text-2xl lg:text-3xl text-justify'>
							<p className='mb-10'>
								The top-down, arcade-like cardboard tank shooter you've been looking for!
							</p>
							<p className='mb-10'>
								Play alongside - or against - your friends, in chaos-fueled game modes such as the classic crowd pleasers: Team Deathmatch, King of the Hill, Capture the Flag, and more! Up to ten friends can play together through splitscreen, network, or both simultaneously.
							</p>
							<p className='mb-10 pt-10 text-center'>
								<span className='font-bold'>Click an icon below to go to our Discord or Steam page!</span>
								<br/>
								<span className='text-md sm:text-lg md:lg:text-xl lg:text-2xl'>(or continue to our <Link to='/games' className='underline'>GAMES</Link> page to learn more)</span>
							</p>
							<div className='flex text-3xl whitespace-nowrap items-center justify-center'>
								{PrimaryExternalLinks.map(e => (
									<Link
										key={e.link}
										to={e.link}
										target='_blank'
										rel='noopener noreferrer'
										className='h-full flex flex-col justify-center hover:text-[#F9A21D] ml-8 lg:ml-16 last:mr-8'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='currentColor'
											viewBox='0 0 50 50'
											strokeWidth={.1}
											stroke='currentColor'
											style={{ width: e.regularSize, height: e.regularSize}}
										>
											<path d={e.path}/>
										</svg>
									</Link>
								))}
							</div>
							{/* <p className=''>
								Check out more <Link to='/games' className='underline'>HERE</Link> on our games page, or head straight to the Steam store page <Link to='https://discord.gg/R6zmACcw6r' target='_blank' rel='noopener noreferrer' className='underline'>HERE</Link>!
							</p> */}
						</div>
					</div>
				}
			/>
			{/* BREAK */}
			<Divider message='Slap Stag'/>
			{/* SLAP STAG LLC */}
			<ParallaxSlice
				mediaRatio={0.5625}
				background={
					<img
						className='h-full w-full opacity-20 blur-sm'
						src='/PressBg.png'
					/>
					// <video className='opacity-20 h-full w-full' src='/HomepageVideo2_v2.mp4' autoPlay muted loop/>
				}
				children={
					<div
						className='xl:w-[1000px] m-auto'
						style={{ paddingTop: NAVBAR_HEIGHT }}
					>
						<div className='w-[340px] sm:w-[600px] md:w-[700px] xl:w-[1000px] mx-auto my-16 flex flex-col justify-center text-white'>
							<p className='text-xl sm:text-3xl md:text-4xl lg:text-5xl font-slapstag_solid mb-10'>
								We make games!
							</p>
							<div className='text-lg sm:text-xl md:lg:text-2xl lg:text-3xl text-justify'>
								<p className='mb-10'>
									We are a small, independent game development studio! We specialize in small-to-medium-scale net co-op and local co-op games.
								</p>
								<p className='mb-10 pt-10 text-center font-bold'>
									Click an icon below to go any of our social medias!
								</p>
								<div className='flex flex-wrap text-3xl whitespace-nowrap items-center justify-center'>
									{SecondaryExternalLinks.map(e => (
										<Link
											key={e.link}
											to={e.link}
											target='_blank'
											rel='noopener noreferrer'
											className='hover:text-[#F9A21D]'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='currentColor'
												viewBox='0 0 50 50'
												strokeWidth={.1}
												stroke='currentColor'
												style={{ width: '10rem', height: '10rem'}}
											>
												<path d={e.path}/>
											</svg>
										</Link>
									))}
								</div>
							</div>
						</div>
					</div>
				}
			/>
		</StandardFormat>
	);
};
export default Homepage;
