import React, { FC, useState, useEffect, useRef, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';



interface StandardFormatProps {
	dontRenderNavbar?: boolean;
	dontRenderFooter?: boolean;
	children?: ReactNode;
}



const StandardFormat:FC<StandardFormatProps> = ({ dontRenderNavbar, dontRenderFooter, children }) => {
	const { pathname } = useLocation();

	//Scroll to top when path changes
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	
	
	dontRenderNavbar ??= false;
	dontRenderFooter ??= false;

	const divRef = useRef<HTMLDivElement>(null);
	const [size, setSize] = useState({x: 0, y: 0});

	useEffect(() => {
		if (!divRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setSize({x: (divRef.current?.clientWidth ?? 0), y: (divRef.current?.clientHeight ?? 0)})
		});
		resizeObserver.observe(divRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<div ref={divRef} className='w-full h-screen min-w-[360px]'>
			<Navbar width={size.x}/>
			<div className='w-full h-auto min-h-full flex flex-col'>
				{children}
				{/* <div className='grow'/> */}
				<Footer/>
			</div>
		</div>
	);
};
export default StandardFormat;
